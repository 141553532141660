.shops-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -8.7rem;
    margin-top: -6rem;
    padding-top: 7.8rem;

    @include breakpoint($mobile) {
        margin-top: -3rem;
    }

    &__item {
        margin-left: 8.7rem;
        margin-top: 6rem;

        @include breakpoint($mobile) {
            margin-top: 3rem;
        }
    }
}