.main-screen {
    background: url('../img/bg-main-screen.jpg') no-repeat 50% / cover;
    color: #fff;

    &__inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: FuturaCondensed;
        font-size: 1.8rem;
        min-height: 49rem;
        justify-content: space-between;
        padding-bottom: 2.6rem;
    }

    &__head {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__logo {
        align-self: flex-start;
        border: 1px solid #fff;
        border-top: 0;

        @include breakpoint($mobile) {
            max-width: 15rem;
        }
    }

    &__made {
        @include breakpoint($mobile) {
            max-width: 13rem;
        }
    }

    &__content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        color: #fff;
        flex-shrink: 0;
        font-family: FuturaCondensedMedium;
        font-size: 10.2rem;
        font-weight: 500;
        line-height: 1.23;
        margin: 0;
        max-width: 100%;
        text-align: center;
        text-shadow: 0 0 2rem rgba(0, 0, 0, 0.8);

        sup {
            line-height: 1;
            top: -0.5em;
            vertical-align: middle;
        }
    }

    &__info {
        color: #e1e1e1;
        font-family: FuturaMedium;
        font-size: 1.8rem;
        line-height: 1.22;
    }
}