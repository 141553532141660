.gels-screen {
    overflow: hidden;
    padding-bottom: 7rem;
    padding-top: 7rem;
    position: relative;

    @include breakpoint($mobile) {
        padding-bottom: 10rem;
        padding-top: 10rem;
    }
}