@include fontFace('FuturaCondensed', '../fonts/FuturaCondensed/FuturaCondensed');
@include fontFace('FuturaCondensedMedium', '../fonts/FuturaCondensedMedium/FuturaCondensedMedium');
@include fontFace('FuturaCondensedPlain', '../fonts/FuturaCondensedPlain/FuturaCondensedPlain');
@include fontFace('FuturaMedium', '../fonts/FuturaMedium/FuturaMedium');
@include fontFace('FuturaNewDemi', '../fonts/FuturaNewDemi/FuturaNewDemi');
// @include fontFace('ALSRubl', '../fonts/ALSRubl/ALSRubl');

@font-face {
    font-family: 'ALS Rubl';
    src: url('../fonts/ALSRubl/ALSRubl.eot');
    src: url('../fonts/ALSRubl/ALSRubl.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ALSRubl/ALSRubl.woff2') format('woff2'),
         url('../fonts/ALSRubl/ALSRubl.woff') format('woff'),
         url('../fonts/ALSRubl/ALSRubl.ttf') format('truetype'),
         url('../fonts/ALSRubl/ALSRubl.svg#ALSRubl') format('svg');
    font-weight: normal;
    font-style: normal;
}

