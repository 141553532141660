.site-section {
    &:nth-child(even) {
        background: radial-gradient(30.91% 50% at 50% 50%, #fff 0%, #f2f2f2 100%);
    }

    &:nth-child(odd) {
        background: radial-gradient(30.91% 50% at 50% 50%, #fff 0%, #e0ecf0 100%);
    }

    &__label {
        background: #fff;
        box-shadow: 0 -1.5rem 2rem rgba(0, 0, 0, 0.03);
        color: #4b4b4c;
        font-family: FuturaMedium;
        font-size: 2rem;
        left: 0;
        line-height: 1.3125;
        padding: 4.5rem 3rem;
        position: absolute;
        text-decoration: none;
        top: 11.8rem;
        writing-mode: tb-rl;
        writing-mode: vertical-rl;
        z-index: 9;

        @include breakpoint($mobile) {
            top: 0;
            writing-mode: horizontal-tb;
        }
    }
}