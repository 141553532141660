.site-section {
    &:nth-child(even) {
        .products {
            @include breakpoint($mobile) {
                flex-direction: column;
            }
        }
    }
}

.products {
    $self: &;

    align-items: center;
    display: flex;
    font-family: FuturaMedium;
    justify-content: center;

    @include breakpoint($mobile) {
        flex-direction: column-reverse;
    }

    &__img-wrap {
        flex-shrink: 0;
        position: relative;
        z-index: 2;

        @include breakpoint($mobile) {
            margin-top: 3.2rem;
        }
    }

    &__data {
        align-items: center;
        background: #fff;
        display: flex;
        padding: 4.8rem 1.6rem;
        max-width: 100%;

        &_condoms-default {
            margin-right: -19rem;
            padding-right: 21rem;
        }

        &_condoms-long {
            margin-left: -6rem;
            padding-left: 6rem;
        }

        &_gels-default {
            margin-right: -6rem;
            padding-right: 6rem;

            .products__num {
                font-size: 7.4rem;
            }
        }

        &_gels-long {
            margin-left: -1.6rem;
            padding-left: 1.6rem;

            .products__num {
                font-size: 7.4rem;
            }
        }

        @include breakpoint($mobile) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
    }

    &__content {
        margin-left: 3.2rem;
        text-align: left;

        @include breakpoint($mobile) {
            margin-left: 1.2rem;
        }

        &_info {
            border-right: 1px solid #fec800;
            padding-right: 3.2rem;

            @include breakpoint($mobile) {
                padding-right: 1.2rem;
            }
        }
    }

    &__label {
        color: #fec800;
        line-height: 1.3125;
    }

    &__num {
        font-size: 9.4rem;
        font-weight: 500;
        line-height: 1;

        @include breakpoint($mobile) {
            font-size: 4.7rem;
        }
    }

    &__cost-label {
        font-size: 2.2rem;
        white-space: nowrap;

        @include breakpoint($mobile) {
            white-space: normal;
            word-break: break-word;
        }
    }

    &__cost {
        display: block;
        font-size: 5.2rem;
        line-height: 1.33;

        @include breakpoint($mobile) {
            font-size: 2.6rem;
        }
    }

    &__ruble {
        font-size: 2.8rem;
        text-decoration: line-through;
    }
}