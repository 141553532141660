.cost-screen {
    padding-bottom: 10.3rem;
    padding-top: 8.8rem;

    @include breakpoint($mobile) {
        padding-bottom: 5.15rem;
        padding-top: 4.4rem;
    }

    &__title {
        color: #2e2e2e;
        font-family: FuturaCondensedPlain;
        font-size: 4.2rem;
        font-weight: 500;
        line-height: 1.24;
        margin: 0;
        text-align: center;
    }
}