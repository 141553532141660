$container-width: 1202px;

/* COLORS */

$color-black: #000;
$color-white: #fff;

/* MEDIA QUERIES */

$tablet: 1024px;
$mobile: 767px;
