.site-footer {
    background: #f9f9f9;
    color: #adadad;
    font-size: 1.4rem;
    padding: 8rem 0 10rem;

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: -1rem;

        @include breakpoint($mobile) {
            flex-direction: column;
        }
    }

    &__item {
        margin: 1rem;

        &_notice {
            font-family: FuturaNewDemi;
            font-size: 4.2rem;
            font-weight: 500;
            line-height: 1.26;
            text-align: right;
            text-transform: uppercase;

            @include breakpoint($mobile) {
                text-align: center;
            }
        }
    }

    &__copyrights {
        margin-top: 4rem;

        p {
            margin: 0;
        }

        @include breakpoint($mobile) {
            text-align: center;
        }
    }
}
